<template>
  <header class="send-header">
    <div class="send-header__title flex--space-between--center main-text">
      <div class="flex--align-center">
        <div
          class="send-header__arrow_icon icon-arrow-long-left custom-icon"
          @click="returnToPrevious"
        ></div>
        <div class="vertical-divider otherXS"></div>
        <slot name="header-title"></slot>
      </div>
      <div class="icon-close custom-icon" @click="close"></div>
    </div>
    <div class="divider"></div>
  </header>
</template>

<script>
import goToOpenedPatient from '@/modules/dashboard/components/mixins/goToOpenedPatient';

import { isMobileDevice } from '@/modules/dashboard/utils';

export default {
  name: 'ModalHeaderWithPrevious',
  mixins: [goToOpenedPatient],
  props: {
    currentModalName: {
      type: String,
      required: true
    },
    previousModalName: {
      type: String,
      required: true
    },
    previousModalParams: {
      type: Object,
      default: () => ({})
    },
    isCustomCloseHandler: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    returnToPrevious() {
      if (isMobileDevice()) {
        this.$router.go(-1);
      }

      this.closeCurrentModal();
      this.$modal.show(this.previousModalName, this.previousModalParams);
    },
    close() {
      if (this.isCustomCloseHandler) {
        this.$emit('close-modal');

        return;
      }

      if (!isMobileDevice()) {
        this.closeCurrentModal({ cancel: true });
        this.$modal.hide(this.previousModalName);

        return;
      }

      const isRedirectedToPatient = this.goToOpenedPatient();

      if (isRedirectedToPatient) {
        return;
      }

      this.$router.push({ name: 'Dashboard' });
    },
    closeCurrentModal(event = {}) {
      if (this.currentModalName) {
        this.$modal.hide(this.currentModalName);
        this.$emit('after-modal-close', event);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/icons';
@import '../../../../../assets/scss/common/text';

.send-header {
  &__title {
    height: 50px;
    padding: 0 20px;
  }
}

.vertical-divider {
  height: 50px;
  margin: 0 15px;
}

.icon-close {
  padding: 10px;
  background-size: 12px;
}

@media (max-width: 767px) {
  .send-header {
    &__arrow_icon {
      margin-right: 10px;
    }

    &__title {
      padding-left: 15px;
    }
  }
}
</style>
